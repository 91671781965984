<template>
  <header class="app-header">
    <AppLink
      to="/dashboard"
      link-component="router-link"
      class="app-header__brand"
    >
      <BaseIcon
        icon="netZeroInsights"
        alt="Net Zero Insights"
        size="100px auto"
        class="app-header__brand__logo"
      />
    </AppLink>

    <SearchForm v-model="searchResultsText" />

    <div class="app-header__actions">
      <ActionButtons
        :web-customer="webCustomerStore.webCustomer"
        :claim-profile-url="claimProfileURL"
        v-model:show-add-company-modal="showAddCompanyModal"
        v-model:show-add-fund-modal="showAddFundModal"
      />
      <AppFloatingMenu
        v-model="isFloatingMenuOpen"
        :options="menuOptions"
        side="right"
      >
        <template #content>
          <UserAvatar :initials="userInitials" v-model="isFloatingMenuOpen" />
        </template>
      </AppFloatingMenu>
    </div>

    <AppNewCompanyModal
      v-model="showAddCompanyModal"
      :is-loading="isLoading"
      :name="searchResultsText"
      @add="handleAddNewCompany"
    />

    <FundOverviewEditSection v-model="showAddFundModal" label="Add Fund" />
  </header>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import AppFloatingMenu from "@/components/app/AppFloatingMenu/AppFloatingMenu.vue";
import useWebCustomerStore from "@/stores/webCustomerStore/useWebCustomerStore";
import { useSearchResults } from "@/shared/composables/useSearchResults/useSearchResults";
import { useNavigationMenu } from "@/shared/composables/useNavigationMenu/useNavigationMenu";
import { useSearchNavigation } from "@/shared/composables/useSearchNavigation/useSearchNavigation";
import { ClaimProfileSteps } from "@/shared/types/profileClaim";
import AppNewCompanyModal from "@/components/dashboard/AppNewCompanyModal/AppNewCompanyModal.vue";
import AppLink from "@/components/app/AppLink/AppLink.vue";
import SearchForm from "@/components/app/AppHeader/components/SearchForm.vue";
import ActionButtons from "@/components/app/AppHeader/components/ActionButtons.vue";
import UserAvatar from "@/components/app/AppHeader/components/UserAvatar.vue";
import { NewCompanyModel } from "@/shared/types/organization";
import organizationsService from "@/shared/services/organizationsService/organizationsService";
import { defaultServerErrorMessage } from "@/shared/constants/errors";
import { redirect } from "@/shared/helpers/redirect/redirect";
import { AxiosError } from "axios";
import useNotificationsStore from "@/stores/notificationsStore/useNotificationsStore";
import BaseIcon from "@/components/app/BaseIcon/BaseIcon.vue";
import FundOverviewEditSection from "@/components/funds/FundOverviewEditSection/FundOverviewEditSection.vue";

const webCustomerStore = useWebCustomerStore();
const { searchResultsText } = useSearchResults();

useSearchNavigation({
  searchText: searchResultsText,
});

const isFloatingMenuOpen = ref(false);
const showAddCompanyModal = ref(false);
const showAddFundModal = ref(false);
const isLoading = ref(false);
const notificationsStore = useNotificationsStore();

const userInitials = computed(() => {
  const { name, surname } = webCustomerStore.webCustomer;

  if (!name || !surname) return "";

  return `${name[0]}${surname[0]}`.toUpperCase();
});

const claimProfileURL = computed(() => {
  return `/claim-profile/${webCustomerStore.webCustomer.profileClaimToken}/${ClaimProfileSteps.BASIC_INFORMATION}`;
});

const { menuOptions } = useNavigationMenu();

const handleAddNewCompany = async (newCompany: NewCompanyModel) => {
  try {
    isLoading.value = true;
    const newAddedCompany =
      await organizationsService.addNewCompany(newCompany);
    if (newAddedCompany.data.directURL) {
      redirect(`/organization/${newAddedCompany.data.directURL}`);
    }
    showAddCompanyModal.value = false;
  } catch (e) {
    const errorMessage =
      (e as AxiosError).response?.status === 400
        ? "Invalid URL"
        : defaultServerErrorMessage;
    notificationsStore.notify(errorMessage, "danger", 3000);
  } finally {
    isLoading.value = false;
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/functions.scss";
@import "@/styles/colors.scss";

.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: rem(16px) rem(20px) rem(16px) rem(35px);
  height: rem(64px);
  width: 100%;
  position: fixed;
  z-index: 11;
  background-color: $white;
  box-sizing: border-box;
  box-shadow:
    0 rem(4px) rem(6px) rem(-1px) rgba(0, 0, 0, 0.06),
    0 rem(2px) rem(4px) rem(-1px) rgba(0, 0, 0, 0.03);

  &__brand {
    display: flex;
    align-items: center;

    &__logo {
      margin-top: rem(5px);
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: rem(16px);
  }
}
</style>
