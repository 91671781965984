import { defineComponent as _defineComponent } from 'vue'
import { resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

import { computed, shallowRef, watchEffect } from "vue";
import { defineAsyncComponent } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseIcon',
  props: {
    icon: {},
    size: {},
    color: {}
  },
  setup(__props: any) {

const props = __props;

const iconComponent = shallowRef(null);

const binding = computed(() => {
  const style: Record<string, string> = {};

  if (props.color) {
    style.color = props.color;
  }

  if (props.size) {
    const [width, height] = props.size.split(" ");
    return {
      width,
      height: height || width,
      style,
    };
  }

  return { style };
});

watchEffect(async () => {
  if (props.icon) {
    iconComponent.value = await defineAsyncComponent(
      () => import(`@/components/icons/${props.icon}-icon.vue`),
    );
  }
});

return (_ctx: any,_cache: any) => {
  return (iconComponent.value)
    ? (_openBlock(), _createBlock(_resolveDynamicComponent(iconComponent.value), _normalizeProps(_mergeProps({ key: 0 }, binding.value)), null, 16))
    : (_openBlock(), _createElementBlock("span", _mergeProps({
        key: 1,
        class: "base-icon__reserve"
      }, binding.value), null, 16))
}
}

})