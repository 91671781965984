<template>
  <AppDropDown
    :search-value="searchModel"
    :is-loading="isLoading"
    :allow-html="allowHtml"
    :options="options"
    :is-empty-state-clickable="isEmptyStateClickable"
    :key-property="keyProperty"
    :label-property="labelProperty"
    :search-property="searchProperty"
    :is-local-search="isLocalSearch"
    @select="handleSelect"
    ref="drop-down"
  >
    <template #target="{ focus, reset }">
      <AppInput
        v-model="searchModel"
        :error="error"
        icon="search"
        :placeholder="placeholder"
        :disabled="isDisabled"
        @input="reset"
        @focus="focus(true)"
      >
        <template v-if="clearable && searchModel" #trailing>
          <AppButton
            type="empty"
            icon="cross"
            icon-size="10px"
            padding="6px"
            @click="clearSearchbar"
          />
        </template>
      </AppInput>
    </template>
    <template #result="{ option, index, selectedIndex }">
      <slot
        name="result"
        :option="option"
        :index="index"
        :selectedIndex="selectedIndex"
        :text="searchModel"
      />
    </template>
    <template #append> <slot name="append" /> </template>
  </AppDropDown>
</template>

<script setup lang="ts" generic="T extends AppSearchbarOption">
import { defineAsyncComponent, ref, watch, useTemplateRef } from "vue";
import { AppSearchbarOption } from "@/shared/types/components";
import { debounce } from "@/shared/helpers/debounce/debounce";
import AppInput from "@/components/app/AppInput/AppInput.vue";
import AppDropDown from "@/components/app/AppDropDown/AppDropDown.vue";

const AppButton = defineAsyncComponent(
  () => import("@/components/app/AppButton/AppButton.vue"),
);

const {
  keepSelection,
  isEmptyStateClickable,
  debounceFunction,
  debounceDuration = 500,
  placeholder = "Type to search",
  searchProperty = "label",
  keyProperty = "id",
} = defineProps<{
  options: T[];
  placeholder?: string;
  keyProperty?: keyof AppSearchbarOption;
  labelProperty?: keyof AppSearchbarOption;
  searchProperty?: keyof AppSearchbarOption;
  allowHtml?: boolean;
  isLocalSearch?: boolean;
  clearable?: boolean;
  keepSelection?: boolean;
  isDisabled?: boolean;
  isEmptyStateClickable?: boolean;
  isLoading?: boolean;
  error?: boolean;
  debounceFunction?: (value: string, ...args: never[]) => void;
  debounceDuration?: number;
}>();

const emit = defineEmits<{
  (event: "select", value: T): void;
  (event: "input", value: string): void;
  (event: "clear"): void;
}>();

const dropDownComponent = useTemplateRef<typeof AppDropDown>("drop-down");
const searchModel = ref("");
const selectedResult = ref<T>();

const fetchSearchResults =
  debounceFunction && debounce(debounceFunction, debounceDuration);

const handleSelect = (option: T) => {
  if (option[keyProperty] !== -1 || isEmptyStateClickable) {
    emit("select", option);
  }

  if (keepSelection && option[keyProperty] !== -1) {
    searchModel.value = (option[searchProperty] as string) || searchModel.value;

    selectedResult.value = option;
  } else {
    searchModel.value = "";
  }
};

const clearSearchbar = () => {
  searchModel.value = "";

  emit("clear");
};

watch(
  () => searchModel.value,
  (newValue) => {
    emit("input", newValue);

    if (
      !selectedResult.value ||
      selectedResult.value[searchProperty] !== newValue
    ) {
      fetchSearchResults?.(newValue);
    }
  },
);

defineExpose({
  searchModel,
  selectedResult,
  clearSearchbar,
  dropDownComponent,
});
</script>
