import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withKeys as _withKeys, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = ["onKeydown"]
const _hoisted_2 = ["onMouseover", "onClick", "innerHTML"]
const _hoisted_3 = ["onMouseover", "onClick"]

import { ref, computed, watch, useTemplateRef, onMounted } from "vue";
import AppLoader from "@/components/app/AppLoader/AppLoader.vue";
import { AppSearchbarOption } from "@/shared/types/components";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppDropDown',
  props: {
    options: {},
    keyProperty: { default: "id" },
    labelProperty: { default: "label" },
    searchProperty: { default: "label" },
    allowHtml: { type: Boolean },
    isLocalSearch: { type: Boolean, default: true },
    keepSelection: { type: Boolean },
    isEmptyStateClickable: { type: Boolean },
    isLoading: { type: Boolean },
    searchValue: { default: "" },
    inverted: { type: Boolean },
    selectedOption: {},
    maxResults: { default: 5 }
  },
  emits: ["select"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

_useCssVars(_ctx => ({
  "b48be022": (dropDownHeight.value)
}))



const emit = __emit;

let observer: MutationObserver;
const dropDownHeight = ref("0");
const isFocused = ref(false);
const selectedIndex = ref(0);
const appDropDownListItems = useTemplateRef<HTMLLIElement[]>(
  "appDropDownListItem",
);
const appDropDownOptions =
  useTemplateRef<HTMLUListElement>("appDropDownOptions");
const appDropDown = useTemplateRef<HTMLDivElement>("appDropDown");

const searchTermLower = computed(() => __props.searchValue?.toLowerCase());

const filteredOptions = computed(() => {
  if (!__props.isLocalSearch || !searchTermLower.value) {
    return __props.options;
  }

  return __props.options.filter((option) =>
    ((option[__props.searchProperty] || "") as string)
      .toLowerCase()
      .includes(searchTermLower.value),
  );
});

const isResult = computed(() => {
  return isFocused.value && (__props.isLoading || filteredOptions.value.length > 0);
});

const resetSelectedIndex = () => {
  selectedIndex.value = 0;
};

const handleOptionMouseOver = (index: number) => {
  selectedIndex.value = index;
};

const hideDropDown = () => {
  isFocused.value = false;

  resetSelectedIndex();
};

const selectOption = (option: T) => {
  emit("select", option);

  hideDropDown();
};

const handleEnterKey = () => {
  if (isResult.value) {
    selectOption(filteredOptions.value[selectedIndex.value]);
  }
};

const matchScrolling = () => {
  const listItemHeight =
    appDropDownListItems.value![selectedIndex.value].clientHeight;

  if (selectedIndex.value >= __props.maxResults) {
    appDropDownOptions.value!.scrollTop =
      listItemHeight * (selectedIndex.value - __props.maxResults + 1);
  } else {
    appDropDownOptions.value!.scrollTop = 0;
  }
};

const handleDownKey = () => {
  if (isResult.value) {
    if (selectedIndex.value < filteredOptions.value.length - 1) {
      selectedIndex.value++;
    } else {
      selectedIndex.value = 0;
    }

    matchScrolling();
  }
};

const handleUpKey = () => {
  if (isResult.value) {
    if (selectedIndex.value > 0) {
      selectedIndex.value--;
    } else {
      selectedIndex.value = filteredOptions.value.length - 1;
    }

    matchScrolling();
  }
};

const handleTabKey = () => {
  if (isFocused.value) {
    isFocused.value = false;

    resetSelectedIndex();
  }
};

const handleFocusToggler = (newValue: boolean) => {
  isFocused.value = newValue;
};

const setAppDropDownOptionsMaxHeight = () => {
  if (__props.options.length) {
    const maxHeight = [...appDropDownOptions.value!.children]
      .slice(0, __props.maxResults)
      .reduce((accumulator, b) => accumulator + b.clientHeight, 0);

    dropDownHeight.value = `${
      maxHeight === 0
        ? `${40 * Math.min(appDropDownOptions.value!.children.length, __props.maxResults)}`
        : maxHeight
    }px`;
  }
};

watch(
  () => appDropDownOptions.value,
  (element) => {
    if (element) {
      observer?.disconnect();
      observer?.observe(element, {
        childList: true,
        subtree: true,
      });
    }
  },
);

onMounted(() => {
  setAppDropDownOptionsMaxHeight();
  observer = new MutationObserver(setAppDropDownOptionsMaxHeight);
});

__expose({ isFocused });

return (_ctx: any,_cache: any) => {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "app-drop-down",
    ref_key: "appDropDown",
    ref: appDropDown,
    onKeydown: [
      _withKeys(_withModifiers(handleUpKey, ["stop"]), ["up"]),
      _withKeys(_withModifiers(handleDownKey, ["stop"]), ["down"]),
      _withKeys(handleTabKey, ["tab"]),
      _withKeys(_withModifiers(handleEnterKey, ["prevent"]), ["enter"])
    ]
  }, [
    _renderSlot(_ctx.$slots, "target", {
      expanded: isResult.value,
      focus: handleFocusToggler,
      reset: resetSelectedIndex
    }),
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass([
        'app-drop-down__container',
        { 'app-drop-down__container--inverted': _ctx.inverted },
      ])
    }, [
      _withDirectives(_createVNode(AppLoader, { class: "app-drop-down__loader" }, null, 512), [
        [_vShow, _ctx.isLoading]
      ]),
      _renderSlot(_ctx.$slots, "content", {}, () => [
        _withDirectives(_createElementVNode("ul", {
          class: "app-drop-down__options",
          ref_key: "appDropDownOptions",
          ref: appDropDownOptions
        }, [
          (_ctx.allowHtml)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(filteredOptions.value, (option, index) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: option[_ctx.keyProperty],
                  class: _normalizeClass([
                'app-drop-down__option',
                { 'app-drop-down__option--hover': selectedIndex.value === index },
                {
                  'app-drop-down__option--selected':
                    option[_ctx.keyProperty] === _ctx.selectedOption,
                },
              ]),
                  onMouseover: ($event: any) => (handleOptionMouseOver(index)),
                  onClick: ($event: any) => (selectOption(option)),
                  innerHTML: option[_ctx.labelProperty],
                  ref_for: true,
                  ref: "appDropDownListItem"
                }, null, 42, _hoisted_2))
              }), 128))
            : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(filteredOptions.value, (option, index) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: option[_ctx.keyProperty],
                  class: _normalizeClass([
                'app-drop-down__option',
                { 'app-drop-down__option--hover': selectedIndex.value === index },
                {
                  'app-drop-down__option--selected':
                    option[_ctx.keyProperty] === _ctx.selectedOption,
                },
              ]),
                  onMouseover: ($event: any) => (handleOptionMouseOver(index)),
                  onClick: ($event: any) => (selectOption(option)),
                  ref_for: true,
                  ref: "appDropDownListItem"
                }, [
                  _renderSlot(_ctx.$slots, "result", {
                    option: option,
                    index: index,
                    selectedIndex: selectedIndex.value
                  }, () => [
                    _createTextVNode(_toDisplayString(option[_ctx.labelProperty]), 1)
                  ])
                ], 42, _hoisted_3))
              }), 128))
        ], 512), [
          [_vShow, !_ctx.isLoading]
        ])
      ]),
      _renderSlot(_ctx.$slots, "append")
    ], 2), [
      [_vShow, isResult.value]
    ])
  ], 40, _hoisted_1)), [
    [_directive_click_outside, hideDropDown]
  ])
}
}

})