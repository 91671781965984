export function debounce<T extends (...args: never[]) => void>(
  callback: T,
  delay = 500,
) {
  let timeoutId = 0;

  return function (...args: never[]) {
    clearTimeout(timeoutId);

    timeoutId = window.setTimeout(() => callback(...args), delay);
  } as T;
}
