<template>
  <VueDatePicker
    v-model="model"
    :enable-time-picker="false"
    :month-change-on-scroll="false"
    :disabled="disabled"
    :placeholder="placeholder"
    :required="required"
    :teleport="true"
    position="center"
    v-bind="$attrs"
  />
</template>

<script setup lang="ts">
import { computed } from "vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

const { modelValue, formatGetter, formatSetter } = defineProps<{
  modelValue?: Date | string;
  disabled?: boolean;
  placeholder?: string;
  required?: boolean;
  formatGetter?: (value: Date | string | undefined) => unknown;
  formatSetter?: (value: unknown) => Date | string | undefined;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", value: Date | string | undefined): void;
}>();

const model = computed({
  get() {
    return formatGetter?.(modelValue) || modelValue;
  },

  set(newModelValue?: Date | string) {
    emit("update:modelValue", formatSetter?.(newModelValue) || newModelValue);
  },
});
</script>

<style lang="scss">
@import "@/styles/functions.scss";

:root {
  --dp-border-radius: 16px;
  --dp-input-padding: 4px 30px 4px 12px;
  --dp-border-radius: 8px !important;
  --dp-action-button-height: 30px !important;
  --dp-action-buttons-padding: 9px !important;
}

.dp__menu {
  box-shadow: 0 rem(12px) rem(24px) rgba(0, 0, 0, 0.25);
}

.dp__calendar_header {
  margin-bottom: rem(5px);
}

.dp__theme_light {
  --dp-hover-color: rgba(90, 191, 110, 0.15) !important;
  --dp-cell-size: 25px !important;
  --dp-row-margin: unset !important;
}
</style>
