import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "app-select__header" }
const _hoisted_3 = ["placeholder", "disabled"]
const _hoisted_4 = {
  key: 0,
  class: "app-select__placeholder"
}
const _hoisted_5 = {
  key: 1,
  class: "app-select__selected"
}

import { AppSearchbarOption } from "@/shared/types/components";
import { computed, ref, watch } from "vue";
import { isEmpty } from "@/shared/helpers/validators/validators";
import AppButton from "@/components/app/AppButton/AppButton.vue";
import AppDropDown from "@/components/app/AppDropDown/AppDropDown.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppSelect',
  props: /*@__PURE__*/_mergeModels({
    options: {},
    placeholder: { default: "Select" },
    error: { type: Boolean },
    disabled: { type: Boolean },
    inverted: { type: Boolean },
    searchable: { type: Boolean },
    unselectable: { type: Boolean },
    isLoading: { type: Boolean },
    keyProperty: { default: "id" },
    labelProperty: {},
    resetInputAfterSelect: { type: Boolean },
    searchProperty: { default: "label" }
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["search", "select"], ["update:modelValue"]),
  setup(__props: any, { expose: __expose, emit: __emit }) {

const model = _useModel<AppSearchbarOption>(__props, "modelValue");



const emit = __emit;

const searchQuery = ref("");

const searchModel = computed({
  get: (): string =>
    searchQuery.value || (model.value?.[__props.searchProperty] as string) || "",
  set: (val: string) => {
    searchQuery.value = val;
    emit("search", val);
  },
});

const showPlaceholder = computed(
  () => __props.placeholder !== undefined && isEmpty(model.value?.[__props.searchProperty]),
);

const isBlurred = ref(false);

const selectListItem = (item: AppSearchbarOption) => {
  model.value = item;
  emit("select", item);

  if (__props.resetInputAfterSelect) {
    searchQuery.value = "";
  }
};

const handleFocus = (expanded: boolean, focus: (value: boolean) => void) => {
  if (!__props.disabled) {
    focus(!expanded);
  }
};

const handleUnselect = () => {
  model.value = {};
  searchModel.value = "";
};

watch(
  () => model.value,
  (newValue?: AppSearchbarOption) => {
    searchQuery.value =
      (newValue?.[__props.searchProperty] as string) || searchQuery.value;
  },
);

watch(
  () => searchQuery.value,
  (newValue: string) => {
    if (newValue !== model.value?.[__props.searchProperty]) {
      model.value = {};
    }
  },
);

__expose({ handleUnselect });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(AppDropDown, {
    "search-value": searchModel.value,
    "is-loading": _ctx.isLoading,
    options: _ctx.options,
    "key-property": _ctx.keyProperty,
    "label-property": _ctx.labelProperty,
    "search-property": _ctx.searchProperty,
    "is-local-search": _ctx.searchable,
    inverted: _ctx.inverted,
    "selected-option": model.value?.[_ctx.keyProperty],
    onSelect: selectListItem
  }, {
    target: _withCtx(({ expanded, focus, reset }) => [
      _createElementVNode("div", {
        class: _normalizeClass([
          'app-select',
          {
            'app-select--disabled': _ctx.disabled,
            'app-select--error': _ctx.error && isBlurred.value,
          },
        ]),
        tabindex: "0",
        onClick: _withModifiers(($event: any) => (handleFocus(expanded, focus)), ["stop"]),
        onBlurOnce: _cache[1] || (_cache[1] = ($event: any) => (isBlurred.value = true))
      }, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.unselectable && model.value?.[_ctx.searchProperty])
            ? (_openBlock(), _createBlock(AppButton, {
                key: 0,
                onClick: _withModifiers(
              () => {
                handleUnselect();
                reset();
              }
            , ["stop"]),
                icon: "cross",
                "icon-size": "14px",
                padding: "0",
                type: "empty",
                "icon-color": "#232426"
              }, null, 8, ["onClick"]))
            : _createCommentVNode("", true),
          (_ctx.searchable)
            ? _withDirectives((_openBlock(), _createElementBlock("input", {
                key: 1,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchModel).value = $event)),
                class: "app-select__input",
                placeholder: _ctx.placeholder,
                disabled: _ctx.disabled
              }, null, 8, _hoisted_3)), [
                [_vModelText, searchModel.value]
              ])
            : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                (showPlaceholder.value)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.placeholder), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(model.value?.[_ctx.searchProperty]), 1))
              ], 64))
        ]),
        (!_ctx.disabled)
          ? (_openBlock(), _createBlock(AppButton, {
              key: 0,
              icon: "arrow-down",
              "icon-size": "14px",
              padding: "0",
              type: "empty",
              class: _normalizeClass([
            'app-select__toggler',
            { 'app-select__toggler--collapsed': !expanded },
          ])
            }, null, 8, ["class"]))
          : _createCommentVNode("", true)
      ], 42, _hoisted_1)
    ]),
    result: _withCtx(({ option, index, selectedIndex }) => [
      _renderSlot(_ctx.$slots, "result", {
        option: option,
        index: index,
        selectedIndex: selectedIndex
      })
    ]),
    _: 3
  }, 8, ["search-value", "is-loading", "options", "key-property", "label-property", "search-property", "is-local-search", "inverted", "selected-option"]))
}
}

})