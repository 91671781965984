<template>
  <div
    class="app-drop-down"
    ref="appDropDown"
    v-click-outside="hideDropDown"
    @keydown.stop.up="handleUpKey"
    @keydown.stop.down="handleDownKey"
    @keydown.tab="handleTabKey"
    @keydown.prevent.enter="handleEnterKey"
  >
    <slot
      name="target"
      :expanded="isResult"
      :focus="handleFocusToggler"
      :reset="resetSelectedIndex"
    />
    <div
      :class="[
        'app-drop-down__container',
        { 'app-drop-down__container--inverted': inverted },
      ]"
      v-show="isResult"
    >
      <AppLoader v-show="isLoading" class="app-drop-down__loader" />
      <slot name="content">
        <ul
          v-show="!isLoading"
          class="app-drop-down__options"
          ref="appDropDownOptions"
        >
          <template v-if="allowHtml">
            <li
              v-for="(option, index) in filteredOptions"
              :key="option[keyProperty]"
              :class="[
                'app-drop-down__option',
                { 'app-drop-down__option--hover': selectedIndex === index },
                {
                  'app-drop-down__option--selected':
                    option[keyProperty] === selectedOption,
                },
              ]"
              @mouseover="handleOptionMouseOver(index)"
              @click="selectOption(option)"
              v-html="option[labelProperty]"
              ref="appDropDownListItem"
            />
          </template>
          <template v-else>
            <li
              v-for="(option, index) in filteredOptions"
              :key="option[keyProperty]"
              :class="[
                'app-drop-down__option',
                { 'app-drop-down__option--hover': selectedIndex === index },
                {
                  'app-drop-down__option--selected':
                    option[keyProperty] === selectedOption,
                },
              ]"
              @mouseover="handleOptionMouseOver(index)"
              @click="selectOption(option)"
              ref="appDropDownListItem"
            >
              <slot
                name="result"
                :option="option"
                :index="index"
                :selectedIndex="selectedIndex"
              >
                {{ option[labelProperty] }}
              </slot>
            </li>
          </template>
        </ul>
      </slot>
      <slot name="append" />
    </div>
  </div>
</template>

<script setup lang="ts" generic="T extends AppSearchbarOption">
import { ref, computed, watch, useTemplateRef, onMounted } from "vue";
import AppLoader from "@/components/app/AppLoader/AppLoader.vue";
import { AppSearchbarOption } from "@/shared/types/components";

const {
  options,
  allowHtml,
  isLoading,
  searchValue = "",
  keyProperty = "id",
  labelProperty = "label",
  searchProperty = "label",
  isLocalSearch = true,
  maxResults = 5,
} = defineProps<{
  options: T[];
  keyProperty?: keyof AppSearchbarOption;
  labelProperty?: keyof AppSearchbarOption;
  searchProperty?: keyof AppSearchbarOption;
  allowHtml?: boolean;
  isLocalSearch?: boolean;
  keepSelection?: boolean;
  isEmptyStateClickable?: boolean;
  isLoading?: boolean;
  searchValue?: string;
  inverted?: boolean;
  selectedOption?: unknown;
  maxResults?: number;
}>();

const emit = defineEmits<{
  (event: "select", value: T): void;
}>();

let observer: MutationObserver;
const dropDownHeight = ref("0");
const isFocused = ref(false);
const selectedIndex = ref(0);
const appDropDownListItems = useTemplateRef<HTMLLIElement[]>(
  "appDropDownListItem",
);
const appDropDownOptions =
  useTemplateRef<HTMLUListElement>("appDropDownOptions");
const appDropDown = useTemplateRef<HTMLDivElement>("appDropDown");

const searchTermLower = computed(() => searchValue?.toLowerCase());

const filteredOptions = computed(() => {
  if (!isLocalSearch || !searchTermLower.value) {
    return options;
  }

  return options.filter((option) =>
    ((option[searchProperty] || "") as string)
      .toLowerCase()
      .includes(searchTermLower.value),
  );
});

const isResult = computed(() => {
  return isFocused.value && (isLoading || filteredOptions.value.length > 0);
});

const resetSelectedIndex = () => {
  selectedIndex.value = 0;
};

const handleOptionMouseOver = (index: number) => {
  selectedIndex.value = index;
};

const hideDropDown = () => {
  isFocused.value = false;

  resetSelectedIndex();
};

const selectOption = (option: T) => {
  emit("select", option);

  hideDropDown();
};

const handleEnterKey = () => {
  if (isResult.value) {
    selectOption(filteredOptions.value[selectedIndex.value]);
  }
};

const matchScrolling = () => {
  const listItemHeight =
    appDropDownListItems.value![selectedIndex.value].clientHeight;

  if (selectedIndex.value >= maxResults) {
    appDropDownOptions.value!.scrollTop =
      listItemHeight * (selectedIndex.value - maxResults + 1);
  } else {
    appDropDownOptions.value!.scrollTop = 0;
  }
};

const handleDownKey = () => {
  if (isResult.value) {
    if (selectedIndex.value < filteredOptions.value.length - 1) {
      selectedIndex.value++;
    } else {
      selectedIndex.value = 0;
    }

    matchScrolling();
  }
};

const handleUpKey = () => {
  if (isResult.value) {
    if (selectedIndex.value > 0) {
      selectedIndex.value--;
    } else {
      selectedIndex.value = filteredOptions.value.length - 1;
    }

    matchScrolling();
  }
};

const handleTabKey = () => {
  if (isFocused.value) {
    isFocused.value = false;

    resetSelectedIndex();
  }
};

const handleFocusToggler = (newValue: boolean) => {
  isFocused.value = newValue;
};

const setAppDropDownOptionsMaxHeight = () => {
  if (options.length) {
    const maxHeight = [...appDropDownOptions.value!.children]
      .slice(0, maxResults)
      .reduce((accumulator, b) => accumulator + b.clientHeight, 0);

    dropDownHeight.value = `${
      maxHeight === 0
        ? `${40 * Math.min(appDropDownOptions.value!.children.length, maxResults)}`
        : maxHeight
    }px`;
  }
};

watch(
  () => appDropDownOptions.value,
  (element) => {
    if (element) {
      observer?.disconnect();
      observer?.observe(element, {
        childList: true,
        subtree: true,
      });
    }
  },
);

onMounted(() => {
  setAppDropDownOptionsMaxHeight();
  observer = new MutationObserver(setAppDropDownOptionsMaxHeight);
});

defineExpose({ isFocused });
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/functions.scss";
@import "@/styles/mixins.scss";

.app-drop-down {
  position: relative;

  &__loader {
    margin: rem(20px) auto;
  }

  &__container {
    border: rem(1px) solid $inputBorder;
    border-radius: rem(8px);
    overflow: hidden;
    background-color: $white;
    position: absolute;
    width: -moz-available;
    width: -webkit-fill-available;
    width: available;
    z-index: 3;

    &--inverted {
      bottom: calc(100% + rem(2px));
      top: unset;
    }
  }

  &__options {
    margin: unset;
    padding: unset;
    list-style: none;
    overflow: auto;
    max-height: v-bind(dropDownHeight);
  }

  &__option {
    cursor: pointer;
    padding: rem(8px);
    user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    @include truncate();

    &--hover {
      background-color: $hover;
    }

    &--selected {
      color: $gray;
    }
  }
}
</style>
