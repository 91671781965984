import { ref, Ref, inject, reactive, computed } from "vue";
import { Fund, FundMoney } from "@/shared/types/fund";
import { isValidUrl, isEmpty } from "@/shared/helpers/validators/validators";
import formatNumberWithCommas from "@/shared/helpers/formatNumberWithCommas/formatNumberWithCommas";
import useWebCustomerStore from "@/stores/webCustomerStore/useWebCustomerStore";
import { AppSearchbarOption } from "@/shared/types/components";
import { TBInvestor } from "@/shared/types/filters";
import fundService from "@/shared/services/fundService/fundService";
import { defaultServerErrorMessage } from "@/shared/constants/errors";
import useNotificationsStore from "@/stores/notificationsStore/useNotificationsStore";

export const useFundOverviewEdit = () => {
  const { isCurrencyEuro, getCurrency } = useWebCustomerStore();
  const { notify } = useNotificationsStore();

  const initialData = ref<Fund>({
    id: -1,
    name: "",
    website: "",
    thesisId: undefined,
    typeId: undefined,
    instrumentId: undefined,
    startDate: undefined,
    endDate: undefined,
    targetSize: undefined,
    targetSizeCurrency: "",
    targetSizeDate: undefined,
    totalCapitalRaised: undefined,
    totalCapitalRaisedCurrency: "",
    totalCapitalRaisedDate: undefined,
    deployedCapital: undefined,
    deployedCapitalCurrency: "",
    deployedCapitalDate: undefined,
    realisedValue: undefined,
    realisedValueCurrency: "",
    realisedValueDate: undefined,
    unrealisedValue: undefined,
    unrealisedValueCurrency: "",
    unrealisedValueDate: undefined,
    vintageYear: undefined,
    investorIDs: [],
    netIRR: undefined,
  });

  const fund = inject<Ref<Fund>>("fund", initialData);

  const fundData = ref({ ...fund.value });

  const userCurrency = {
    label: getCurrency,
    value: getCurrency,
  };

  const selectedManagers = ref<AppSearchbarOption[]>([]);

  const formatedValues = reactive({
    [FundMoney.TARGET_SIZE]: "",
    [FundMoney.TOTAL_CAPITAL_RAISED]: "",
    [FundMoney.DEPLOYED_CAPITAL]: "",
    [FundMoney.REALISED_VALUE]: "",
    [FundMoney.UNREALISED_VALUE]: "",
  });

  const selectedCurrencies = reactive({
    [FundMoney.TARGET_SIZE]: { ...userCurrency },
    [FundMoney.TOTAL_CAPITAL_RAISED]: { ...userCurrency },
    [FundMoney.DEPLOYED_CAPITAL]: { ...userCurrency },
    [FundMoney.REALISED_VALUE]: { ...userCurrency },
    [FundMoney.UNREALISED_VALUE]: { ...userCurrency },
  });

  const isDisabledSubmit = computed(() => {
    return !!(
      isEmpty(fundData.value.name) ||
      isEmpty(fundData.value.thesisId) ||
      isEmpty(fundData.value.typeId) ||
      isEmpty(fundData.value.instrumentId) ||
      isMoneyNotValid(FundMoney.TARGET_SIZE) ||
      isMoneyNotValid(FundMoney.TOTAL_CAPITAL_RAISED) ||
      isMoneyNotValid(FundMoney.DEPLOYED_CAPITAL) ||
      isMoneyNotValid(FundMoney.REALISED_VALUE) ||
      isMoneyNotValid(FundMoney.UNREALISED_VALUE) ||
      (fundData.value.website && !isValidUrl(fundData.value.website)) ||
      (fundData.value.targetSize && !fundData.value.targetSizeCurrency) ||
      (fundData.value.totalCapitalRaised &&
        !fundData.value.totalCapitalRaisedCurrency) ||
      (fundData.value.deployedCapital &&
        !fundData.value.deployedCapitalCurrency) ||
      (fundData.value.realisedValue && !fundData.value.realisedValueCurrency) ||
      (fundData.value.unrealisedValue &&
        !fundData.value.unrealisedValueCurrency)
    );
  });

  const isMoneyNotValid = (key: FundMoney) =>
    !!(
      formatedValues[key] &&
      !/^\d{1,3}(,\d{3})*(\.\d+)?$/.test(formatedValues[key])
    );

  const formatInvestor = (investor: TBInvestor): string => {
    return investor.website
      ? `${investor.name} (${investor.website})`
      : investor.name;
  };

  const updateFormatedValues = (value: string | number, key: FundMoney) => {
    const asNumber = Number(`${value}`.replaceAll(",", ""));
    if (asNumber) {
      fundData.value[key] = asNumber;
      formatedValues[key] = formatNumberWithCommas(asNumber);
    } else {
      fundData.value[key] = 0;
      formatedValues[key] = "";
    }
  };

  const updateFundData = (newData: Fund) => {
    fund.value = newData;
  };

  const handleManagerSearchbarSelect = (investor: TBInvestor) => {
    selectedManagers.value.push({
      ...investor,
      label: investor.name,
      id: investor.investorID,
    });
  };

  const handleRemoveManager = (id: number | string) => {
    selectedManagers.value = selectedManagers.value?.filter(
      (investor: AppSearchbarOption) => investor.id !== id,
    );
  };

  const setFundData = () => {
    if (fundData.value.managers) {
      selectedManagers.value = fundData.value.managers.map(
        (manager: TBInvestor) => ({
          ...manager,
          label: manager.name,
          id: manager.investorID,
        }),
      );
    }

    Object.values(FundMoney).forEach((key) => {
      updateFormatedValues(
        (isCurrencyEuro
          ? fundData.value[`${key}EUR` as keyof Fund]
          : fundData.value[`${key}USD` as keyof Fund]) as string,
        key,
      );
      selectedCurrencies[key] = { ...userCurrency };
    });
  };

  const save = async () => {
    try {
      const isNewFund = !fundData.value.id || fundData.value.id === -1;

      const { data } = isNewFund
        ? await fundService.createFund(fundData.value)
        : await fundService.updateFund(fundData.value, fundData.value.id);

      fundData.value = { ...data };
      updateFundData(data);
      setFundData();

      notify(
        isNewFund
          ? "Fund successfully created!"
          : "Fund data successfully updated!",
        "success",
      );

      return data;
    } catch (error) {
      notify(defaultServerErrorMessage, "danger");
      throw error;
    }
  };

  return {
    isDisabledSubmit,
    selectedCurrencies,
    selectedManagers,
    formatedValues,
    fundData,
    updateFundData,
    updateFormatedValues,
    formatInvestor,
    isMoneyNotValid,
    setFundData,
    handleManagerSearchbarSelect,
    handleRemoveManager,
    save,
  };
};
