<template>
  <div class="action-buttons">
    <AppButton
      type="empty"
      icon="positive-check"
      icon-size="28"
      icon-color="#2795e3"
      href="https://meetings-eu1.hubspot.com/federico-cristoforoni"
      label="Upgrade"
      class="action-buttons__button"
      v-if="webCustomer.profileClaimToken"
    />
    <AppButton
      label="Edit company"
      type="empty"
      icon="edit"
      icon-size="28"
      :to="claimProfileUrl"
      class="action-buttons__button"
      v-if="webCustomer.preview && webCustomer.profileClaimToken"
    />
    <AppFloatingMenu
      v-model="isAddMenuOpen"
      :options="addMenuOptions"
      @select="handleAddMenuSelect"
    >
      <template #content>
        <AppButton
          type="empty"
          label="Add"
          icon="add"
          icon-size="28"
          class="action-buttons__button"
          @click="isAddMenuOpen = !isAddMenuOpen"
        />
      </template>
    </AppFloatingMenu>
  </div>
</template>

<script setup lang="ts">
import AppButton from "@/components/app/AppButton/AppButton.vue";
import AppFloatingMenu from "@/components/app/AppFloatingMenu/AppFloatingMenu.vue";
import type { WebCustomer } from "@/shared/types/common";
import type { AppFloatingMenuOption } from "@/shared/types/components";
import { ref, computed } from "vue";
import useWebCustomerStore from "@/stores/webCustomerStore/useWebCustomerStore";

const { isUserAdmin } = useWebCustomerStore();

defineProps<{
  webCustomer: WebCustomer;
  claimProfileUrl: string;
}>();

const showAddCompanyModal = defineModel("showAddCompanyModal");
const showAddFundModal = defineModel("showAddFundModal");
const isAddMenuOpen = ref(false);

const addMenuOptions = computed<AppFloatingMenuOption[]>(() => {
  const options: AppFloatingMenuOption[] = [
    {
      name: "add-company",
      label: "Add Company",
      iconSrc: "company",
    },
  ];

  if (isUserAdmin) {
    options.push({
      name: "add-fund",
      label: "Add Fund",
      iconSrc: "fund",
    });
  }

  return options;
});

const handleAddMenuSelect = (option: AppFloatingMenuOption) => {
  if (option.name === "add-company") {
    showAddCompanyModal.value = true;
  } else if (option.name === "add-fund") {
    showAddFundModal.value = true;
  }
  isAddMenuOpen.value = false;
};
</script>

<style scoped lang="scss">
@import "@/styles/functions.scss";
@import "@/styles/colors.scss";

.action-buttons {
  display: flex;
  align-items: center;
  gap: rem(6px);
  padding-right: rem(8px);

  &__button {
    padding: rem(10px);
    padding-top: rem(8px);
    padding-bottom: rem(8px);
    color: black;

    &:hover {
      background-color: $backgroundAlternative;
    }
  }
}
</style>
