<template>
  <ul class="selected-items-list">
    <li v-for="item in list" :key="item.id" class="selected-items-list__item">
      <AppButton
        @click="handleRemove(item.id!)"
        padding="0"
        icon="cross"
        icon-size="11px"
        type="empty"
      />
      <span class="selected-items-list__item__name">
        {{ item.label }}
      </span>
    </li>
  </ul>
</template>

<script setup lang="ts" generic="T extends AppSearchbarOption">
import AppButton from "@/components/app/AppButton/AppButton.vue";
import { AppSearchbarOption } from "@/shared/types/components";

withDefaults(
  defineProps<{
    list: T[];
    flexDirection?: string;
    gap?: string;
    itemGap?: string;
  }>(),
  {
    flexDirection: "column",
    gap: "4px",
    itemGap: "4px",
  },
);

const emit = defineEmits<{
  (event: "remove", value: number | string): void;
}>();

const handleRemove = (id: number | string) => emit("remove", id);
</script>

<style scoped lang="scss">
@import "@/styles/functions.scss";
@import "@/styles/colors.scss";

.selected-items-list {
  margin: rem(12px) 0 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: v-bind(flexDirection);
  gap: v-bind(gap);
  flex-wrap: wrap;

  &__item {
    display: flex;
    align-items: center;
    gap: v-bind(itemGap);

    &__name {
      color: $coreBlack;
      font-size: rem(12px);
      font-weight: 400;
    }
  }
}
</style>
