<template>
  <div
    :class="['app-edit-sidebar', { 'app-edit-sidebar--hidden': !model }]"
    @click="close"
  >
    <transition name="fade">
      <div v-if="model" class="app-edit-sidebar__form" @click.stop>
        <div class="app-edit-sidebar__form__header">
          <h3 class="app-edit-sidebar__form__title">{{ title }}</h3>

          <AppButton
            @click="close"
            padding="0"
            icon="cross"
            icon-size="24px"
            type="empty"
          />
        </div>

        <div class="app-edit-sidebar__form__content">
          <slot name="content" />
        </div>

        <div class="app-edit-sidebar__form__footer">
          <AppButton @click="close" label="Cancel" type="empty" />

          <slot name="submit-button" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { watch } from "vue";
import AppButton from "@/components/app/AppButton/AppButton.vue";

const model = defineModel<boolean>({ required: true });

defineProps<{
  title: string;
}>();

const close = () => {
  model.value = false;
};

watch(
  () => model.value,
  () => {
    document.documentElement.style.overflow = model.value ? "hidden" : "unset";
  },
);
</script>

<style scoped lang="scss">
@import "@/styles/functions.scss";
@import "@/styles/colors.scss";

.app-edit-sidebar {
  position: fixed;
  z-index: 15;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  transition: 0.4s ease;

  &--hidden {
    visibility: hidden;
    opacity: 0;
  }

  &__form {
    position: absolute;
    right: 0;
    background: $white;
    height: 100vh;
    width: rem(600px);
    box-shadow:
      0 rem(4px) rem(38px) rem(31px) rgba(0, 0, 0, 0.06),
      0 rem(18px) rem(20px) rem(16px) rgba(0, 0, 0, 0.06);

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: rem(12px) rem(24px);
      background: $background;
    }

    &__title {
      font-size: rem(20px);
      font-weight: 600;
    }

    &__content {
      overflow: auto;
      height: calc(100vh - 224px);
      padding: rem(28px) rem(24px);
      display: grid;
      grid-auto-rows: auto;
      gap: rem(24px);
    }

    &__footer {
      display: flex;
      justify-content: flex-end;
      border-top: rem(1px) solid $border;
      gap: rem(8px);
      padding: rem(24px);
    }

    .app-button--empty {
      color: $backdrop;
      height: fit-content;
    }
  }
}

.fade-enter-from,
.fade-leave-to {
  transition: all 0.3s;
  opacity: 0;
  transform: translateX(50%);
}
</style>
