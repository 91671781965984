<template>
  <router-view v-if="isFreeTrialLayout" />
  <UnauthorizedView v-else-if="isUnauthorizedLayout" />
  <ClaimProfileLayout v-else-if="isProfileClaimLayout" />

  <AppEmptyLayout v-else-if="isPublicLayout" />

  <AppLayout v-else-if="isRouteReady">
    <router-view></router-view>

    <AppFreeTrialModal
      v-model="freeTrialModel"
      :title="title"
      :message="message"
    />
  </AppLayout>

  <TransitionGroup tag="div" name="notifications" class="app__notifications">
    <AppToast
      v-for="notification in notificationsStore.notifications"
      :key="notification.id"
      v-bind="notification"
      @remove="notificationsStore.removeNotification(notification.id)"
    />
  </TransitionGroup>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapStores, mapState, mapActions } from "pinia";
import useWebCustomerStore from "@/stores/webCustomerStore/useWebCustomerStore";
import AppLayout from "@/components/app/AppLayout/AppLayout.vue";
import AppEmptyLayout from "@/components/layouts/AppEmptyLayout/AppEmptyLayout.vue";
import useOrganizationsStore from "@/stores/organizationsStore/useOrganizationsStore";
import useFreeTrialStore from "@/stores/useFreeTrialStore/useFreeTrialStore";
import AppFreeTrialModal from "@/components/app/AppFreeTrialModal/AppFreeTrialModal.vue";
import UnauthorizedView from "@/views/UnauthorizedView/UnauthorizedView.vue";
import ClaimProfileLayout from "@/layouts/ClaimProfileLayout/ClaimProfileLayout.vue";
import useNotificationsStore from "@/stores/notificationsStore/useNotificationsStore";
import AppToast from "@/components/app/AppToast/AppToast.vue";

export default defineComponent({
  name: "App",

  components: {
    AppLayout,
    AppFreeTrialModal,
    UnauthorizedView,
    ClaimProfileLayout,
    AppToast,
    AppEmptyLayout,
  },

  computed: {
    ...mapStores(
      useWebCustomerStore,
      useOrganizationsStore,
      useNotificationsStore,
    ),
    ...mapState(useFreeTrialStore, ["title", "message", "showModal"]),

    isUnauthorizedLayout() {
      return this.$route.meta.layout === "unauthorized";
    },

    isFreeTrialLayout() {
      return this.$route.meta.layout === "free-trial";
    },

    isRouteReady() {
      return this.$route.path === window.location.pathname;
    },

    isProfileClaimLayout() {
      return this.$route.meta.layout === "claim-profile";
    },

    isPublicLayout() {
      return this.$route.meta.layout === "public";
    },

    freeTrialModel: {
      get() {
        return this.showModal;
      },
      set() {
        this.hideFreeTrialModal();
      },
    },
  },

  methods: {
    ...mapActions(useFreeTrialStore, ["hideFreeTrialModal"]),
  },
});
</script>

<style lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/functions.scss";

body {
  margin: 0;
  position: relative;
}

.app__notifications {
  gap: rem(8px);
  z-index: 999;
  right: rem(16px);
  bottom: rem(16px);
  display: flex;
  overflow: hidden;
  position: fixed;
  flex-direction: column;
}

.notifications-enter-active,
.notifications-leave-active {
  transition: all 0.5s ease;
}
.notifications-enter-from,
.notifications-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
