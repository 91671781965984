import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "selected-items-list" }
const _hoisted_2 = { class: "selected-items-list__item__name" }

import AppButton from "@/components/app/AppButton/AppButton.vue";
import { AppSearchbarOption } from "@/shared/types/components";


export default /*@__PURE__*/_defineComponent({
  __name: 'SelectedItemsList',
  props: {
    list: {},
    flexDirection: { default: "column" },
    gap: { default: "4px" },
    itemGap: { default: "4px" }
  },
  emits: ["remove"],
  setup(__props: any, { emit: __emit }) {

_useCssVars(_ctx => ({
  "5d8b3822": (_ctx.flexDirection),
  "62cd8a72": (_ctx.gap),
  "5d0f2c5f": (_ctx.itemGap)
}))



const emit = __emit;

const handleRemove = (id: number | string) => emit("remove", id);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item) => {
      return (_openBlock(), _createElementBlock("li", {
        key: item.id,
        class: "selected-items-list__item"
      }, [
        _createVNode(AppButton, {
          onClick: ($event: any) => (handleRemove(item.id!)),
          padding: "0",
          icon: "cross",
          "icon-size": "11px",
          type: "empty"
        }, null, 8, ["onClick"]),
        _createElementVNode("span", _hoisted_2, _toDisplayString(item.label), 1)
      ]))
    }), 128))
  ]))
}
}

})