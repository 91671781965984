import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-new-drop-down-option" }
const _hoisted_2 = { class: "app-new-drop-down-option__image" }
const _hoisted_3 = { class: "app-new-drop-down-option__name" }
const _hoisted_4 = { class: "app-new-drop-down-option__link" }

import BaseIcon from "@/components/app/BaseIcon/BaseIcon.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppNewDropdownOption',
  props: {
    icon: { default: "plus-circle" },
    title: {},
    description: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(BaseIcon, {
        icon: _ctx.icon,
        color: "#5abf6e"
      }, null, 8, ["icon"])
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.description), 1)
    ])
  ]))
}
}

})