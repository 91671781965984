import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "action-buttons" }

import AppButton from "@/components/app/AppButton/AppButton.vue";
import AppFloatingMenu from "@/components/app/AppFloatingMenu/AppFloatingMenu.vue";
import type { WebCustomer } from "@/shared/types/common";
import type { AppFloatingMenuOption } from "@/shared/types/components";
import { ref, computed } from "vue";
import useWebCustomerStore from "@/stores/webCustomerStore/useWebCustomerStore";


export default /*@__PURE__*/_defineComponent({
  __name: 'ActionButtons',
  props: /*@__PURE__*/_mergeModels({
    webCustomer: {},
    claimProfileUrl: {}
  }, {
    "showAddCompanyModal": {},
    "showAddCompanyModalModifiers": {},
    "showAddFundModal": {},
    "showAddFundModalModifiers": {},
  }),
  emits: ["update:showAddCompanyModal", "update:showAddFundModal"],
  setup(__props: any) {

const { isUserAdmin } = useWebCustomerStore();



const showAddCompanyModal = _useModel(__props, "showAddCompanyModal");
const showAddFundModal = _useModel(__props, "showAddFundModal");
const isAddMenuOpen = ref(false);

const addMenuOptions = computed<AppFloatingMenuOption[]>(() => {
  const options: AppFloatingMenuOption[] = [
    {
      name: "add-company",
      label: "Add Company",
      iconSrc: "company",
    },
  ];

  if (isUserAdmin) {
    options.push({
      name: "add-fund",
      label: "Add Fund",
      iconSrc: "fund",
    });
  }

  return options;
});

const handleAddMenuSelect = (option: AppFloatingMenuOption) => {
  if (option.name === "add-company") {
    showAddCompanyModal.value = true;
  } else if (option.name === "add-fund") {
    showAddFundModal.value = true;
  }
  isAddMenuOpen.value = false;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.webCustomer.profileClaimToken)
      ? (_openBlock(), _createBlock(AppButton, {
          key: 0,
          type: "empty",
          icon: "positive-check",
          "icon-size": "28",
          "icon-color": "#2795e3",
          href: "https://meetings-eu1.hubspot.com/federico-cristoforoni",
          label: "Upgrade",
          class: "action-buttons__button"
        }))
      : _createCommentVNode("", true),
    (_ctx.webCustomer.preview && _ctx.webCustomer.profileClaimToken)
      ? (_openBlock(), _createBlock(AppButton, {
          key: 1,
          label: "Edit company",
          type: "empty",
          icon: "edit",
          "icon-size": "28",
          to: _ctx.claimProfileUrl,
          class: "action-buttons__button"
        }, null, 8, ["to"]))
      : _createCommentVNode("", true),
    _createVNode(AppFloatingMenu, {
      modelValue: isAddMenuOpen.value,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((isAddMenuOpen).value = $event)),
      options: addMenuOptions.value,
      onSelect: handleAddMenuSelect
    }, {
      content: _withCtx(() => [
        _createVNode(AppButton, {
          type: "empty",
          label: "Add",
          icon: "add",
          "icon-size": "28",
          class: "action-buttons__button",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (isAddMenuOpen.value = !isAddMenuOpen.value))
        })
      ]),
      _: 1
    }, 8, ["modelValue", "options"])
  ]))
}
}

})