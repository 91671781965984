<template>
  <AppDropDown
    :search-value="searchModel"
    :is-loading="isLoading"
    :options="options"
    :key-property="keyProperty"
    :label-property="labelProperty"
    :search-property="searchProperty"
    :is-local-search="searchable"
    :inverted="inverted"
    :selected-option="model?.[keyProperty]"
    @select="selectListItem"
  >
    <template #target="{ expanded, focus, reset }">
      <div
        :class="[
          'app-select',
          {
            'app-select--disabled': disabled,
            'app-select--error': error && isBlurred,
          },
        ]"
        tabindex="0"
        @click.stop="handleFocus(expanded, focus)"
        @blur.once="isBlurred = true"
      >
        <div class="app-select__header">
          <AppButton
            v-if="unselectable && model?.[searchProperty]"
            @click.stop="
              () => {
                handleUnselect();
                reset();
              }
            "
            icon="cross"
            icon-size="14px"
            padding="0"
            type="empty"
            icon-color="#232426"
          />
          <input
            v-if="searchable"
            v-model="searchModel"
            class="app-select__input"
            :placeholder="placeholder"
            :disabled="disabled"
          />

          <template v-else>
            <span v-if="showPlaceholder" class="app-select__placeholder">
              {{ placeholder }}
            </span>
            <span v-else class="app-select__selected">
              {{ model?.[searchProperty] }}
            </span>
          </template>
        </div>
        <AppButton
          v-if="!disabled"
          icon="arrow-down"
          icon-size="14px"
          padding="0"
          type="empty"
          :class="[
            'app-select__toggler',
            { 'app-select__toggler--collapsed': !expanded },
          ]"
        />
      </div>
    </template>
    <template #result="{ option, index, selectedIndex }">
      <slot
        name="result"
        :option="option"
        :index="index"
        :selectedIndex="selectedIndex"
      />
    </template>
  </AppDropDown>
</template>

<script setup lang="ts">
import { AppSearchbarOption } from "@/shared/types/components";
import { computed, ref, watch } from "vue";
import { isEmpty } from "@/shared/helpers/validators/validators";
import AppButton from "@/components/app/AppButton/AppButton.vue";
import AppDropDown from "@/components/app/AppDropDown/AppDropDown.vue";

const model = defineModel<AppSearchbarOption>();

const {
  options,
  searchable,
  disabled,
  resetInputAfterSelect,
  placeholder = "Select",
  searchProperty = "label",
  keyProperty = "id",
} = defineProps<{
  options: AppSearchbarOption[];
  placeholder?: string;
  error?: boolean;
  disabled?: boolean;
  inverted?: boolean;
  searchable?: boolean;
  unselectable?: boolean;
  isLoading?: boolean;
  keyProperty?: keyof AppSearchbarOption;
  labelProperty?: keyof AppSearchbarOption;
  resetInputAfterSelect?: boolean;
  searchProperty?: keyof AppSearchbarOption;
}>();

const emit = defineEmits<{
  (event: "search", value: string): void;
  (event: "select", value: AppSearchbarOption): void;
}>();

const searchQuery = ref("");

const searchModel = computed({
  get: (): string =>
    searchQuery.value || (model.value?.[searchProperty] as string) || "",
  set: (val: string) => {
    searchQuery.value = val;
    emit("search", val);
  },
});

const showPlaceholder = computed(
  () => placeholder !== undefined && isEmpty(model.value?.[searchProperty]),
);

const isBlurred = ref(false);

const selectListItem = (item: AppSearchbarOption) => {
  model.value = item;
  emit("select", item);

  if (resetInputAfterSelect) {
    searchQuery.value = "";
  }
};

const handleFocus = (expanded: boolean, focus: (value: boolean) => void) => {
  if (!disabled) {
    focus(!expanded);
  }
};

const handleUnselect = () => {
  model.value = {};
  searchModel.value = "";
};

watch(
  () => model.value,
  (newValue?: AppSearchbarOption) => {
    searchQuery.value =
      (newValue?.[searchProperty] as string) || searchQuery.value;
  },
);

watch(
  () => searchQuery.value,
  (newValue: string) => {
    if (newValue !== model.value?.[searchProperty]) {
      model.value = {};
    }
  },
);

defineExpose({ handleUnselect });
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/functions.scss";
@import "@/styles/mixins.scss";

.app-select {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
  background: $white;
  border: rem(1px) solid $inputBorder;
  border-radius: rem(8px);
  padding: rem(4px) rem(8px);

  &:hover:not(&--disabled) {
    border-color: $primary;
  }

  &:focus {
    outline: none;
  }

  &--disabled {
    background: $disabled;
    cursor: default;
  }

  &--error {
    border-color: $danger;
  }

  &__input {
    border: 0;
    flex: 1;
    width: 100%;

    &:focus {
      outline: none;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    gap: rem(4px);
    max-width: calc(100% - rem(16px));
    flex: 1;
  }

  &__placeholder {
    color: $gray;
    @include truncate();
  }

  &__selected {
    margin-right: rem(4px);
    @include truncate();
  }

  &__toggler {
    transition: transform 0.4s ease;
    transform: rotate(180deg);

    &--collapsed {
      transform: rotate(0);
    }
  }
}
</style>
