import {
  AddInvestorModel,
  FundingRoundAttachment,
  FundingRoundModel,
} from "@/shared/types/admin";
import type { Currency, FundingRound } from "@/shared/types/common";
import { FundingRoundInvestor, FundingRoundNews } from "@/shared/types/common";
import { MainFilter, TBInvestor } from "@/shared/types/filters";

import { AxiosResponse } from "axios";
import { CompanyInvestor } from "@/shared/types/organization";
import { ExternalProviders } from "@/shared/types/admin";
import axios from "@/plugins/axios/axios";

async function getFundingRoundList(
  mainFilter: MainFilter,
  abortSignal?: AbortSignal,
): Promise<
  AxiosResponse<{
    count: number;
    roundsTotalFunding: number;
    results: FundingRound[];
    selectedCurrency: Currency;
  }>
> {
  return await axios.post("/getFundingRoundList", mainFilter, {
    signal: abortSignal,
  });
}

async function getFundingRoundNewsByRoundId(
  id: number,
): Promise<AxiosResponse<FundingRoundNews[]>> {
  return await axios.get(`/roundNewsByRoundID/${id}`);
}

async function getFundingRoundInvestorsByRoundId(
  id: number,
): Promise<AxiosResponse<FundingRoundInvestor[]>> {
  return await axios.post(`/getFundingRoundInvestors`, [id]);
}

function getInvestorsByName(
  name: string,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<TBInvestor[]>> {
  return axios.get(`/investor/filter/${name}`, {
    signal: abortSignal,
  });
}

function getInvestorsById(
  id: number,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<TBInvestor>> {
  return axios.get(`/investor/${id}`, {
    signal: abortSignal,
  });
}

function getInvestorsByClientId(
  clientId: number,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<CompanyInvestor[]>> {
  return axios.get(`/investors/${clientId}`, {
    signal: abortSignal,
  });
}

function downloadFundingRounds(
  mainFilter: MainFilter,
  contactTypes: number[],
  abortSignal?: AbortSignal,
): Promise<AxiosResponse> {
  return axios.post("/export/downloadFundingRounds", {
    mainFilter,
    contactTypes,
    signal: abortSignal,
  });
}

function getFundingRoundCount(
  mainFilter: MainFilter,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<{ count: number }>> {
  return axios.post("/getFundingRoundCount", mainFilter, {
    signal: abortSignal,
  });
}

function getLargestDeals(abortSignal?: AbortSignal): Promise<AxiosResponse> {
  return axios.get("/largestDealsFilter", {
    signal: abortSignal,
  });
}

function getExits(abortSignal?: AbortSignal): Promise<AxiosResponse> {
  return axios.get("/exitsFilter", {
    signal: abortSignal,
  });
}

function addInvestor(
  data: AddInvestorModel,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<AddInvestorModel>> {
  return axios.post("/investor", data, {
    signal: abortSignal,
  });
}

function addFundingRound(
  data: FundingRoundModel,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse> {
  return axios.post("/fundingRound", data, {
    signal: abortSignal,
  });
}

function editFundingRound(
  data: FundingRoundModel,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse> {
  return axios.put(`/fundingRound/${data.coFundingRoundID}`, data, {
    signal: abortSignal,
  });
}

function removeFundingRound(
  roundID: number,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse> {
  return axios.delete(`/fundingRound/${roundID}`, {
    signal: abortSignal,
  });
}

function getCurrencies(
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<string[]>> {
  return axios.get("/currencies", {
    signal: abortSignal,
  });
}

function getRoundTypes(
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<string[]>> {
  return axios.get("/roundTypes", {
    signal: abortSignal,
  });
}

function getExternalProviders(
  clientId: number,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<ExternalProviders[]>> {
  return axios.get(`/fundingRound/externalProviders/${clientId}`, {
    signal: abortSignal,
  });
}

function attachFundingRoundDocument(
  fundingRoundID: number,
  file: File,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<FundingRoundAttachment>> {
  return axios.post(
    `/fundingRoundDocuments/${fundingRoundID}`,
    { file },
    {
      signal: abortSignal,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );
}

function getFundingRoundAttachments(
  fundingRoundID: number,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<FundingRoundAttachment[]>> {
  return axios.get(`/fundingRoundDocuments/${fundingRoundID}`, {
    signal: abortSignal,
  });
}

function deleteFundingRoundAttachment(
  attachmentID: number,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<boolean>> {
  return axios.delete(`/fundingRoundDocuments/${attachmentID}`, {
    signal: abortSignal,
  });
}

export default {
  getFundingRoundList,
  getFundingRoundNewsByRoundId,
  getFundingRoundInvestorsByRoundId,
  getInvestorsByName,
  getFundingRoundCount,
  getLargestDeals,
  getExits,
  addFundingRound,
  editFundingRound,
  removeFundingRound,
  addInvestor,
  getCurrencies,
  getRoundTypes,
  getExternalProviders,
  getInvestorsById,
  getInvestorsByClientId,
  downloadFundingRounds,
  getFundingRoundAttachments,
  attachFundingRoundDocument,
  deleteFundingRoundAttachment,
};
