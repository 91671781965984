/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "@/plugins/axios/axios";
import type { AxiosResponse } from "axios";

import {
  Fund,
  FundType,
  BaseFundItem,
  FundInvestment,
  FundInvestmentFundingRound,
  FundInvestmentModel,
  FundResource,
  LimitedPartner,
  LimitedPartnerModel,
  LimitedPartnerSearchModel,
} from "@/shared/types/fund";

import { InvestorSearchResult } from "@/shared/types/investor";
import { Startup } from "@/shared/types/common";

function getFundOverview(
  fundId: string,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<Fund>> {
  return axios.get(`/fund/${fundId}`, {
    signal: abortSignal,
  });
}

function getFundInvestorSearch(
  investorId: number,
  type: FundType,
): Promise<AxiosResponse<Fund[]>> {
  return axios.post("/fund/investorSearch", {
    investorId,
    type,
  });
}

function createFundNews(
  news: FundResource,
): Promise<AxiosResponse<FundResource>> {
  return axios.post("/fund/news", {
    ...news,
    fundID: news.fundID,
    description: "test",
  });
}

function updateFundNews(
  news: FundResource,
): Promise<AxiosResponse<FundResource>> {
  return axios.put(`/fund/news/${news.id}`, news);
}

function deleteFundNews(newsId: number): Promise<AxiosResponse<FundResource>> {
  return axios.delete(`/fund/news/${newsId}`);
}

function getFundLimitedPartners(
  fundId: number,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<LimitedPartner[]>> {
  return axios.get(`/fund/${fundId}/limitedPartners`, {
    signal: abortSignal,
  });
}

function searchFundLimitedPartner(
  data: LimitedPartnerSearchModel,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<InvestorSearchResult[]>> {
  return axios.get("/fund/investors/search", {
    params: data,
    signal: abortSignal,
  });
}

function createFundLimitedPartner(
  data: LimitedPartnerModel,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<LimitedPartner>> {
  return axios.post("/fund/limitedPartners", data, {
    signal: abortSignal,
  });
}

function updateFundLimitedPartner(
  id: number,
  data: LimitedPartnerModel,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<LimitedPartner>> {
  return axios.put(`/fund/limitedPartners/${id}`, data, {
    signal: abortSignal,
  });
}

function deleteFundLimitedPartner(
  id: number,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<boolean>> {
  return axios.delete(`/fund/limitedPartners/${id}`, {
    signal: abortSignal,
  });
}

function getFundInvestments(
  fundId: number,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<FundInvestment[]>> {
  return axios.get(`/fund/${fundId}/investments`, {
    signal: abortSignal,
  });
}

function updateFund(data: Fund, fundId: number): Promise<AxiosResponse<Fund>> {
  return axios.put(`/fund/${fundId}`, data);
}

function getFundInstruments(): Promise<AxiosResponse<BaseFundItem[]>> {
  return axios.get("fund/instruments");
}

function getFundTheses(): Promise<AxiosResponse<BaseFundItem[]>> {
  return axios.get("/fund/theses");
}

function getFundTypes(): Promise<AxiosResponse<BaseFundItem[]>> {
  return axios.get("/fund/types");
}

function createFundInvestment(
  data: FundInvestmentModel,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<FundInvestment>> {
  return axios.post(`/fund/investments`, data, {
    signal: abortSignal,
  });
}

function deleteFundInvestment(
  id: number,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<boolean>> {
  return axios.delete(`/fund/investments/${id}`, {
    signal: abortSignal,
  });
}

function searchFundInvestmentCompanies(
  id: number,
  text: string,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<Startup[]>> {
  return axios.get(`/fund/${id}/investeeCompanies?searchText=${text}`, {
    signal: abortSignal,
  });
}

function getFundingRoundsOfFundCompany(
  findID: number,
  clientID: number,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<FundInvestmentFundingRound[]>> {
  return axios.get(`/fund/investments/${findID}/${clientID}`, {
    signal: abortSignal,
  });
}

function getNews(
  fundId: number,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<FundResource[]>> {
  return axios.get(`/fund/${fundId}/news`, {
    signal: abortSignal,
  });
}

function getOtherResources(
  fundId: number,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<FundResource[]>> {
  return axios.get(`/fund/${fundId}/otherSources`, {
    signal: abortSignal,
  });
}

function createOtherResource(
  resource: FundResource,
): Promise<AxiosResponse<FundResource>> {
  return axios.post(`/fund/otherSources`, {
    ...resource,
    fundID: resource.fundID,
    description: "test",
  });
}

function updateOtherResource(
  resource: FundResource,
): Promise<AxiosResponse<FundResource>> {
  return axios.put(`/fund/otherSources/${resource.id}`, resource);
}

function deleteOtherResource(
  resourceId: number,
): Promise<AxiosResponse<FundResource>> {
  return axios.delete(`/fund/otherSources/${resourceId}`);
}

function createFund(data: Fund): Promise<AxiosResponse<Fund>> {
  return axios.post("/fund", data);
}

export default {
  getFundOverview,
  getFundInvestments,
  createFundInvestment,
  deleteFundInvestment,
  searchFundInvestmentCompanies,
  getFundingRoundsOfFundCompany,
  getNews,
  getFundTypes,
  getFundTheses,
  getFundInstruments,
  getFundInvestorSearch,
  createFundNews,
  updateFundNews,
  deleteFundNews,
  getOtherResources,
  createOtherResource,
  updateOtherResource,
  deleteOtherResource,
  getFundLimitedPartners,
  deleteFundLimitedPartner,
  createFundLimitedPartner,
  updateFundLimitedPartner,
  updateFund,
  searchFundLimitedPartner,
  createFund,
};
