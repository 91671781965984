import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from "vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppDatePicker',
  props: {
    modelValue: {},
    disabled: { type: Boolean },
    placeholder: {},
    required: { type: Boolean },
    formatGetter: { type: Function },
    formatSetter: { type: Function }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {



const emit = __emit;

const model = computed({
  get() {
    return __props.formatGetter?.(__props.modelValue) || __props.modelValue;
  },

  set(newModelValue?: Date | string) {
    emit("update:modelValue", __props.formatSetter?.(newModelValue) || newModelValue);
  },
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(VueDatePicker), _mergeProps({
    modelValue: model.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model).value = $event)),
    "enable-time-picker": false,
    "month-change-on-scroll": false,
    disabled: _ctx.disabled,
    placeholder: _ctx.placeholder,
    required: _ctx.required,
    teleport: true,
    position: "center"
  }, _ctx.$attrs), null, 16, ["modelValue", "disabled", "placeholder", "required"]))
}
}

})