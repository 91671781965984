import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "overview-edit-section__form__manager"
}
const _hoisted_2 = { class: "overview-edit-section__form__money-field" }
const _hoisted_3 = { class: "overview-edit-section__form__date-input-field" }
const _hoisted_4 = { class: "overview-edit-section__form__money-field" }
const _hoisted_5 = { class: "overview-edit-section__form__date-input-field" }
const _hoisted_6 = { class: "overview-edit-section__form__money-field" }
const _hoisted_7 = { class: "overview-edit-section__form__money-field" }
const _hoisted_8 = { class: "overview-edit-section__form__money-field" }

import { ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import AppButton from "@/components/app/AppButton/AppButton.vue";
import AppInput from "@/components/app/AppInput/AppInput.vue";
import AppSearchbar from "@/components/app/AppSearchbar/AppSearchbar.vue";
import AppSelect from "@/components/app/AppSelect/AppSelect.vue";
import AppNewDropdownOption from "@/components/app/AppNewDropdownOption/AppNewDropdownOption.vue";
import AppDatePicker from "@/components/app/AppDatePicker/AppDatePicker.vue";
import AppInformationGroup from "@/components/app/AppInformationGroup/AppInformationGroup.vue";
import AppEditSidebar from "@/components/app/AppEditSidebar/AppEditSidebar.vue";
import SelectedItemsList from "@/components/admin/SelectedItemsList/SelectedItemsList.vue";
import AppTooltip from "@/components/app/AppTooltip/AppTooltip.vue";
import AppIcon from "@/components/app/AppIcon/AppIcon.vue";
import fundService from "@/shared/services/fundService/fundService";
import fundingRoundsService from "@/shared/services/fundingRoundsService/fundingRoundsService";
import { AppSearchbarOption } from "@/shared/types/components";
import { FundMoney, BaseFundItem } from "@/shared/types/fund";
import { useFundOverviewEdit } from "@/shared/composables/useFundOverviewEdit/useFundOverviewEdit";
import { copyGreen } from "@/shared/constants/icons";
import { isValidUrl, isEmpty } from "@/shared/helpers/validators/validators";
import { copyToClipboard } from "@/shared/helpers/copyToClipboard/copyToClipboard";
import { TBInvestor } from "@/shared/types/filters";
import { isFulfilled } from "@/shared/helpers/isFulfilled/isFulfilled";
import { AxiosError } from "axios";
import { abortErrorName } from "@/shared/constants/errors";


export default /*@__PURE__*/_defineComponent({
  __name: 'FundOverviewEditSection',
  props: /*@__PURE__*/_mergeModels({
    label: {}
  }, {
    "modelValue": { type: Boolean, ...{ required: true } },
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["open-investor-popup"], ["update:modelValue"]),
  setup(__props: any, { emit: __emit }) {

const route = useRoute();
const router = useRouter();



const emit = __emit;

const showSideBar = _useModel<boolean>(__props, "modelValue");

const {
  isDisabledSubmit,
  selectedCurrencies,
  selectedManagers,
  formatedValues,
  fundData,
  updateFormatedValues,
  formatInvestor,
  isMoneyNotValid,
  setFundData,
  handleManagerSearchbarSelect,
  handleRemoveManager,
  save,
} = useFundOverviewEdit();

let abortController: AbortController;

const isManagerLoading = ref(false);
const isSaveLoading = ref(false);

const managers = ref<TBInvestor[]>([]);

const types = ref<BaseFundItem[]>([]);
const focusOptions = ref<BaseFundItem[]>([]);
const instruments = ref<BaseFundItem[]>([]);
const currencies = ref<AppSearchbarOption[]>([]);

const handleOpenInvestorModal = () => {
  emit("open-investor-popup");
};

const fetchManagers = async (newName: string) => {
  try {
    isManagerLoading.value = true;
    if (abortController) {
      abortController.abort();
    }

    abortController = new AbortController();

    const { data } = await fundingRoundsService.getInvestorsByName(
      newName,
      abortController.signal,
    );

    managers.value = data.length ? data : [{ id: -1, name: "" } as TBInvestor];

    isManagerLoading.value = false;
  } catch (error) {
    managers.value = [];
    if ((error as AxiosError).name !== abortErrorName) {
      isManagerLoading.value = false;
    }
  }
};

watch(
  () => selectedCurrencies,
  () => {
    fundData.value.targetSizeCurrency = selectedCurrencies.targetSize.label;
    fundData.value.totalCapitalRaisedCurrency =
      selectedCurrencies.totalCapitalRaised.label;
    fundData.value.deployedCapitalCurrency =
      selectedCurrencies.deployedCapital.label;
    fundData.value.realisedValueCurrency =
      selectedCurrencies.realisedValue.label;
    fundData.value.unrealisedValueCurrency =
      selectedCurrencies.unrealisedValue.label;
  },
  {
    deep: true,
  },
);

watch(
  () => selectedManagers.value,
  () => {
    fundData.value.investorIDs = selectedManagers.value.map(
      (manager: AppSearchbarOption) => Number(manager.investorID),
    );
  },
  {
    deep: true,
  },
);

watch(
  () => fundData.value,
  () => {
    fundData.value.thesisId = fundData.value.thesis?.id;
    fundData.value.typeId = fundData.value.type?.id;
    fundData.value.instrumentId = fundData.value.instrument?.id;
  },
  {
    deep: true,
  },
);

(async () => {
  try {
    const [fundTypes, fundTheses, fundInstruments, currenciesData] =
      await Promise.allSettled([
        fundService.getFundTypes(),
        fundService.getFundTheses(),
        fundService.getFundInstruments(),
        fundingRoundsService.getCurrencies(),
      ]);

    if (isFulfilled(fundTypes)) {
      types.value = fundTypes.value.data;
    }
    if (isFulfilled(fundTheses)) {
      focusOptions.value = fundTheses.value.data;
    }
    if (isFulfilled(fundInstruments)) {
      instruments.value = fundInstruments.value.data;
    }
    if (isFulfilled(currenciesData)) {
      currencies.value = currenciesData.value.data.map((currency: string) => ({
        label: currency,
        value: currency,
      }));
    }
    setFundData();
  } catch (_) {
    types.value = [] as BaseFundItem[];
    focusOptions.value = [] as BaseFundItem[];
    instruments.value = [] as BaseFundItem[];
    currencies.value = [] as AppSearchbarOption[];
  }
})();

const saveAndRedirect = async () => {
  try {
    isSaveLoading.value = true;
    const savedFund = await save();
    showSideBar.value = false;

    if (!route.params.id) {
      await router.push(`/fund/${savedFund.id}`);
    }
  } catch (error) {
    console.error("Error saving fund:", error);
  } finally {
    isSaveLoading.value = false;
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(AppEditSidebar, {
    modelValue: showSideBar.value,
    "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((showSideBar).value = $event)),
    title: _ctx.label
  }, {
    content: _withCtx(() => [
      _createVNode(AppInformationGroup, {
        label: "Fund name",
        "is-required": ""
      }, {
        default: _withCtx(() => [
          _createVNode(AppInput, {
            modelValue: _unref(fundData).name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(fundData).name) = $event)),
            error: _unref(isEmpty)(_unref(fundData).name),
            placeholder: "Fund name"
          }, null, 8, ["modelValue", "error"])
        ]),
        _: 1
      }),
      _createVNode(AppInformationGroup, {
        label: "Fund focus",
        "is-required": ""
      }, {
        default: _withCtx(() => [
          _createVNode(AppSelect, {
            options: focusOptions.value,
            modelValue: _unref(fundData).thesis,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(fundData).thesis) = $event)),
            error: _unref(isEmpty)(_unref(fundData).thesis),
            searchable: "",
            unselectable: "",
            placeholder: "Fund focus"
          }, null, 8, ["options", "modelValue", "error"])
        ]),
        _: 1
      }),
      _createVNode(AppInformationGroup, {
        label: "Fund type",
        "is-required": ""
      }, {
        default: _withCtx(() => [
          _createVNode(AppSelect, {
            options: types.value,
            modelValue: _unref(fundData).type,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(fundData).type) = $event)),
            error: _unref(isEmpty)(_unref(fundData).type),
            searchable: "",
            unselectable: "",
            placeholder: "Fund type"
          }, null, 8, ["options", "modelValue", "error"])
        ]),
        _: 1
      }),
      _createVNode(AppInformationGroup, {
        label: "Investment instrument",
        "is-required": ""
      }, {
        default: _withCtx(() => [
          _createVNode(AppSelect, {
            options: instruments.value,
            modelValue: _unref(fundData).instrument,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(fundData).instrument) = $event)),
            error: _unref(isEmpty)(_unref(fundData).instrument),
            searchable: "",
            unselectable: "",
            placeholder: "Investment instrument"
          }, null, 8, ["options", "modelValue", "error"])
        ]),
        _: 1
      }),
      _createVNode(AppInformationGroup, { label: "Website" }, {
        default: _withCtx(() => [
          _createVNode(AppInput, {
            modelValue: _unref(fundData).website,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(fundData).website) = $event)),
            error: !!_unref(fundData).website && !_unref(isValidUrl)(_unref(fundData).website),
            placeholder: "Website"
          }, null, 8, ["modelValue", "error"])
        ]),
        _: 1
      }),
      _createVNode(AppInformationGroup, { label: "Fund manager(s)" }, {
        default: _withCtx(() => [
          _createVNode(AppSearchbar, {
            "is-loading": isManagerLoading.value,
            options: managers.value,
            onSelect: _unref(handleManagerSearchbarSelect),
            "is-local-search": false,
            "debounce-function": fetchManagers,
            "debounce-duration": 500,
            clearable: "",
            "max-results": 3,
            "search-property": "name",
            "label-property": "name",
            placeholder: "Fund Manager's Name"
          }, {
            result: _withCtx(({ option, text }) => [
              (option.id === -1)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _cache[22] || (_cache[22] = _createTextVNode(" No managers matching ")),
                    _createElementVNode("strong", null, _toDisplayString(text), 1),
                    _cache[23] || (_cache[23] = _createTextVNode(" found "))
                  ], 64))
                : (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(AppTooltip, { content: "Click to copy Manager's website" }, {
                      default: _withCtx(() => [
                        _createVNode(AppIcon, {
                          onClick: _withModifiers(($event: any) => (_unref(copyToClipboard)(_unref(formatInvestor)(option))), ["stop"]),
                          src: _unref(copyGreen)
                        }, null, 8, ["onClick", "src"])
                      ]),
                      _: 2
                    }, 1024),
                    _createElementVNode("span", null, _toDisplayString(_unref(formatInvestor)(option)), 1)
                  ]))
            ]),
            append: _withCtx(() => [
              _createVNode(AppNewDropdownOption, {
                onClick: handleOpenInvestorModal,
                title: "New manager",
                description: "Add to database"
              })
            ]),
            _: 1
          }, 8, ["is-loading", "options", "onSelect"]),
          _createVNode(SelectedItemsList, {
            list: _unref(selectedManagers),
            onRemove: _unref(handleRemoveManager),
            "flex-direction": "row",
            gap: "8px 16px",
            "item-gap": "8px"
          }, null, 8, ["list", "onRemove"])
        ]),
        _: 1
      }),
      _createVNode(AppInformationGroup, { label: "Start date" }, {
        default: _withCtx(() => [
          _createVNode(AppDatePicker, _mergeProps({
            modelValue: _unref(fundData).startDate,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(fundData).startDate) = $event))
          }, {
            ...(_unref(fundData).endDate && { maxDate: _unref(fundData).endDate }),
          }, { placeholder: "MM-DD-YYYY" }), null, 16, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(AppInformationGroup, { label: "End date" }, {
        default: _withCtx(() => [
          _createVNode(AppDatePicker, _mergeProps({
            modelValue: _unref(fundData).endDate,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_unref(fundData).endDate) = $event))
          }, {
            ...(_unref(fundData).startDate && { minDate: _unref(fundData).startDate }),
          }, { placeholder: "MM-DD-YYYY" }), null, 16, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(AppInformationGroup, { label: "Fund target size" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(AppInput, {
              "model-value": _unref(formatedValues).targetSize,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (
              _unref(updateFormatedValues)($event, _unref(FundMoney).TARGET_SIZE)
            )),
              error: _unref(isMoneyNotValid)(_unref(FundMoney).TARGET_SIZE),
              placeholder: "Fund manager"
            }, null, 8, ["model-value", "error"]),
            _createVNode(AppSelect, {
              options: currencies.value,
              modelValue: _unref(selectedCurrencies).targetSize,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_unref(selectedCurrencies).targetSize) = $event)),
              searchable: "",
              label: "",
              class: "fieldxs"
            }, null, 8, ["options", "modelValue"])
          ])
        ]),
        _: 1
      }),
      _createVNode(AppInformationGroup, { label: "Total capital raised" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(AppInput, {
                "model-value": _unref(formatedValues).totalCapitalRaised,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (
                _unref(updateFormatedValues)($event, _unref(FundMoney).TOTAL_CAPITAL_RAISED)
              )),
                error: _unref(isMoneyNotValid)(_unref(FundMoney).TOTAL_CAPITAL_RAISED),
                placeholder: "Fund manager"
              }, null, 8, ["model-value", "error"]),
              _createVNode(AppSelect, {
                options: currencies.value,
                modelValue: _unref(selectedCurrencies).totalCapitalRaised,
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_unref(selectedCurrencies).totalCapitalRaised) = $event)),
                searchable: "",
                placeholder: "$ USD",
                class: "fieldxs"
              }, null, 8, ["options", "modelValue"])
            ]),
            _createVNode(AppDatePicker, {
              modelValue: _unref(fundData).totalCapitalRaisedDate,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_unref(fundData).totalCapitalRaisedDate) = $event)),
              placeholder: "MM-DD-YYYY"
            }, null, 8, ["modelValue"])
          ])
        ]),
        _: 1
      }),
      _createVNode(AppInformationGroup, { label: "Deployed capital" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(AppInput, {
                "model-value": _unref(formatedValues).deployedCapital,
                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => (
                _unref(updateFormatedValues)($event, _unref(FundMoney).DEPLOYED_CAPITAL)
              )),
                error: _unref(isMoneyNotValid)(_unref(FundMoney).DEPLOYED_CAPITAL),
                placeholder: "Fund manager"
              }, null, 8, ["model-value", "error"]),
              _createVNode(AppSelect, {
                options: currencies.value,
                modelValue: _unref(selectedCurrencies).deployedCapital,
                "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_unref(selectedCurrencies).deployedCapital) = $event)),
                searchable: "",
                placeholder: "",
                class: "fieldxs"
              }, null, 8, ["options", "modelValue"])
            ]),
            _createVNode(AppDatePicker, {
              modelValue: _unref(fundData).deployedCapitalDate,
              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_unref(fundData).deployedCapitalDate) = $event)),
              placeholder: "MM-DD-YYYY"
            }, null, 8, ["modelValue"])
          ])
        ]),
        _: 1
      }),
      _createVNode(AppInformationGroup, { label: "Realised value" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(AppInput, {
              "model-value": _unref(formatedValues).realisedValue,
              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => (
              _unref(updateFormatedValues)($event, _unref(FundMoney).REALISED_VALUE)
            )),
              error: _unref(isMoneyNotValid)(_unref(FundMoney).REALISED_VALUE),
              placeholder: "Fund manager"
            }, null, 8, ["model-value", "error"]),
            _createVNode(AppSelect, {
              options: currencies.value,
              modelValue: _unref(selectedCurrencies).realisedValue,
              "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_unref(selectedCurrencies).realisedValue) = $event)),
              searchable: "",
              placeholder: "",
              class: "fieldxs"
            }, null, 8, ["options", "modelValue"])
          ])
        ]),
        _: 1
      }),
      _createVNode(AppInformationGroup, { label: "Unrealised value" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_8, [
            _createVNode(AppInput, {
              "model-value": _unref(formatedValues).unrealisedValue,
              "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => (
              _unref(updateFormatedValues)($event, _unref(FundMoney).UNREALISED_VALUE)
            )),
              error: _unref(isMoneyNotValid)(_unref(FundMoney).UNREALISED_VALUE),
              placeholder: "Fund manager"
            }, null, 8, ["model-value", "error"]),
            _createVNode(AppSelect, {
              options: currencies.value,
              modelValue: _unref(selectedCurrencies).unrealisedValue,
              "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_unref(selectedCurrencies).unrealisedValue) = $event)),
              searchable: "",
              placeholder: "",
              class: "fieldxs"
            }, null, 8, ["options", "modelValue"])
          ])
        ]),
        _: 1
      }),
      _createVNode(AppInformationGroup, { label: "Net IRR" }, {
        default: _withCtx(() => [
          _createVNode(AppInput, {
            modelValue: _unref(fundData).netIRR,
            "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_unref(fundData).netIRR) = $event)),
            placeholder: "Fund manager"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(AppInformationGroup, { label: "Vintage year" }, {
        default: _withCtx(() => [
          _createVNode(AppInput, {
            modelValue: _unref(fundData).vintageYear,
            "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_unref(fundData).vintageYear) = $event)),
            placeholder: "Fund manager"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      })
    ]),
    "submit-button": _withCtx(() => [
      _createVNode(AppButton, {
        onClick: saveAndRedirect,
        "is-disabled": _unref(isDisabledSubmit),
        "is-loading": isSaveLoading.value,
        label: "Add"
      }, null, 8, ["is-disabled", "is-loading"])
    ]),
    _: 1
  }, 8, ["modelValue", "title"]))
}
}

})