import { Startup, FundingRound } from "@/shared/types/common";
import {
  AppButtonType,
  AppSearchbarOption,
  AppTableColumn,
} from "@/shared/types/components";
import { EmployeeGrowth } from "@/shared/types/profileClaim";
import { InvestorSearchResult } from "@/shared/types/investor";
import { TaxonomySearchResult } from "@/shared/types/taxonomy";
import { FinancialAttachment } from "@/shared/types/admin";
import { SavedSearchType } from "./savedSearches";
import { Component } from "vue";

export interface UniversalSearchResultItem {
  name: string;
  id: number;
}

export interface UniversalSavedSearchResult extends UniversalSearchResultItem {
  searchType: SavedSearchType;
}

export interface UniversalFundSearchResult extends UniversalSearchResultItem {
  website: string;
  logoURL?: string;
}

export interface UniversalMarketResearchSearchResult
  extends UniversalSearchResultItem {
  description: string;
  url: string;
}

export interface UniversalPortfolioSearchResult
  extends UniversalSearchResultItem {
  type: "companiesPortfolio" | "investorsPortfolio";
}

export const enum UniversalSearchResultKey {
  COMPANIES = "companies",
  PORTFOLIOS = "portfolios",
  INVESTORS = "investors",
  FUNDS = "funds",
  DEALS = "deals",
  MARKET_RESEARCH = "marketResearch",
  SAVED_SEARCHES = "savedSearches",
  TAXONOMY_ITEMS = "taxonomyItems",
}

export interface UniversalSearchResult {
  [UniversalSearchResultKey.COMPANIES]: OrganizationSearchResult[];
  [UniversalSearchResultKey.PORTFOLIOS]: UniversalPortfolioSearchResult[];
  [UniversalSearchResultKey.INVESTORS]: InvestorSearchResult[];
  [UniversalSearchResultKey.FUNDS]: UniversalFundSearchResult[];
  [UniversalSearchResultKey.MARKET_RESEARCH]: UniversalMarketResearchSearchResult[];
  [UniversalSearchResultKey.SAVED_SEARCHES]: UniversalSavedSearchResult[];
  [UniversalSearchResultKey.TAXONOMY_ITEMS]: TaxonomySearchResult[];
  [UniversalSearchResultKey.DEALS]: FundingRound[];
}

export type UniversalSearchResultItems =
  | OrganizationSearchResult
  | UniversalPortfolioSearchResult
  | InvestorSearchResult
  | UniversalFundSearchResult
  | UniversalMarketResearchSearchResult
  | UniversalSavedSearchResult
  | TaxonomySearchResult
  | FundingRound;

export interface OrganizationSearchResult {
  city?: string;
  country: string;
  clientID: number;
  directURL: string;
  foundedDate: number;
  fundingAmount: number;
  fundingAmountUSD: number;
  fundingString?: number;
  fundingStringUSD?: number;
  lastRoundAmount?: number;
  lastRoundAmountUSD?: number;
  lastRoundDate?: string;
  lastRoundType?: string;
  id: number;
  clientLogoURL?: string;
  logoURL?: string;
  logo: string;
  name: string;
  pitchLine: string;
  size?: string;
  trlsString?: string;
  website: string;
}

export interface EditCustomColumnContent {
  organization: Startup;
  column: AppTableColumn;
  value: string;
}

export interface UpdateCustomColumnModel {
  columnId: string;
  newCustomColumnName: string;
  callback: () => unknown;
  errorHandler: () => unknown;
}

export interface RemoveCustomColumnModel {
  customColumnId: string;
  callback: () => unknown;
  errorHandler?: () => unknown;
}

export enum ManageColumnsType {
  ORGANIZATIONS = "organisations",
  DEALS = "funding-rounds",
  INVESTORS = "investors",
}

export interface SearchLocation {
  id: number;
  continentName: string;
  continentID: number;
  adminID4?: string;
  adminName4?: string;
  cityID?: string;
  cityName?: string;
  countryID?: string;
  countryName?: string;
}

export interface HQRegion {
  id: number;
  regionName: string;
}

export interface LocationsAndRegionsResponseData {
  continents: SearchLocation[];
  regions: HQRegion[];
  countries: SearchLocation[];
  admins: SearchLocation[];
  cities: SearchLocation[];
}

export interface NewCompanyModel {
  name: string;
  website: string;
  webPresence: string;
  linkedInURL: string;
}
export interface ActionType {
  label: string;
  type: AppButtonType;
  handler: () => unknown;
  isDisabled?: boolean;
}

export interface DuplicateSectionData {
  value: string;
  loading: boolean;
  error: boolean;
}

export interface DuplicatedDataModel {
  keepId: number;
  removeIds: number[];
}

export type ConfirmInfoType = "company" | "investor" | "round";

export interface CloudinaryResponse {
  asset_id: string;
  bytes: number;
  created_at: string;
  etag: string;
  folder: string;
  format: string;
  height: number;
  original_filename: string;
  placeholder: false;
  public_id: string;
  resource_type: string;
  secure_url: string;
  signature: string;
  tags: [];
  type: string;
  url: string;
  version: number;
  version_id: string;
  width: number;
}

export interface StartupSize {
  id?: number | string;
  label?: string | string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface CompanyInfoItem<T = any> {
  value: T;
  label: string;
  logoSrc?: string;
  checkIsValid?: (value: T) => boolean;
}

export interface CompanyInfoSecond {
  location: CompanyInfoItem<AppSearchbarOption>;
  employees: CompanyInfoItem;
  employeesGrowthJSON: CompanyInfoItem<string>;
  linkedin: CompanyInfoItem;
  foundedDate: CompanyInfoItem;
  alternativeNames: CompanyInfoItem<string[]>;
  legalNames: CompanyInfoItem<string[]>;
}

export interface CompanyInfo {
  logo: CompanyInfoItem<undefined | File>;
  name: CompanyInfoItem;
  lastReviewer: CompanyInfoItem;
  lastReviewDate: CompanyInfoItem;
  description: CompanyInfoItem;
  website: CompanyInfoItem;
  location: CompanyInfoItem<AppSearchbarOption>;
  employees: CompanyInfoItem;
  employeesGrowthJSON: CompanyInfoItem<string>;
  linkedin: CompanyInfoItem;
  foundedDate: CompanyInfoItem;
  alternativeNames: CompanyInfoItem<string[]>;
  legalNames: CompanyInfoItem<string[]>;
}

export interface TagTemplate {
  id?: number;
  name: string;
  tags: number[];
}

export interface TaxonomySector {
  id: string;
  sectorId: undefined | number;
  activities: number[];
}

export interface CompanyInvestor {
  id: number;
  name: string;
  firstRoundDate: string;
  roundTypes: string;
  investorID: number;
}

export interface CompanyFinancials {
  id: number;
  ebitda?: string;
  revenues?: string;
  ebitdapercentage: number;
  revenuesPercentage: number;
  ebitdaEstimate?: boolean;
  revenuesEstimate?: boolean;
  currency: string;
  year: string;
}

export interface FinancialInfoItem {
  id: number;
  amountEUR: number;
  amountUSD: number;
  growth: number;
  estimated: boolean;
  referenceDate: string;
  referenceYear: number;
  attachments?: FinancialAttachment[];
}

export interface AssetFinancialInfoItem extends FinancialInfoItem {
  cash: FinancialInfoItem;
  receivables: FinancialInfoItem;
}

export interface LegalDetail {
  id: number | string;
  legalName: string;
  legalForm: string;
  vatNumber: string;
  registryID: string;
}

export interface FinancialInfo {
  assets: AssetFinancialInfoItem[];
  cash?: FinancialInfoItem[];
  receivables?: FinancialInfoItem[];
  earnings: FinancialInfoItem[];
  equity: FinancialInfoItem[];
  liabilities: FinancialInfoItem[];
  revenues: FinancialInfoItem[];
}

export enum FinancialInfoType {
  ASSETS = "assets",
  EARNINGS = "earnings",
  EQUITY = "equity",
  LIABILITIES = "liabilities",
  REVENUES = "revenues",
  CASH = "cash",
  RECEIVABLES = "receivables",
}

export interface ContactRoles {
  name: string;
  id: number | null;
}

export interface ContactModel {
  clientID: number;
  roleID?: number;
  decisionMaker?: boolean;
}

export interface NavigationChildren {
  id?: string | number;
  label: string;
  info?: string;
  isExists: boolean;
  clickable?: boolean;
  hash?: string;
}

export interface NavigationChildrenLink extends NavigationChildren {
  id: string | number;
}

export interface Navigation {
  label: string;
  id: string | number;
  count?: number;
  component: string | Component;
  isExists: boolean;
  beta?: boolean;
  children?: NavigationChildren[];
  isChildrenLink?: boolean;
  // eslint-disable-next-line
  props: Record<string, any>;
}

export interface emptyResults {
  header: string;
  description: string;
}

export interface TabItem {
  name: string;
  title: string;
  content: string;
}

export interface EmployeesItem {
  quarter: string;
  value: number;
  variation: number;
}
export enum OrganizationCardType {
  ORGANIZATION = "organization",
  EXIT_DEALS = "exit-deals",
  TAXONOMY = "taxonomy",
}

export interface TimelinePOJO {
  clientID: number;
  foundedYear: number;
  employeeGrowths: EmployeeGrowth[];
  rounds: FundingRound[];
}

export interface EmployeesGrowthJSON {
  clientID: number;
  source: string;
  employeesGrowthJSON: string;
}

export interface OrganizationNote {
  clientID: number;
  note: string;
}

export interface NewsLabel {
  id: number;
  label: string;
}

export interface OrganisationNews {
  id: number;
  clientID?: number;
  title: string;
  labelIDs?: number[];
  labels?: NewsLabel[];
  description: string;
  newsDate: string;
  url: string;
}

export enum DownloadFormat {
  PDF = "PDF",
  DOC = "DOC",
}

export interface Competitor {
  competitorId: number;
  reasonId: number;
}

export interface CompetitorRequestData {
  clientId: number;
  offset: number;
  limit: number;
  competitors?: Competitor[];
  competitorIds?: number[];
}

export interface EUTaxonomyTag {
  id: number;
  label: string;
}

export interface EUTaxonomy extends EUTaxonomyTag {
  tags: EUTaxonomyTag[];
}

export interface OrganizationSaveAll {
  clientID: number;
  name?: string;
  website?: string;
  description?: string;
  trlID?: number;
  tagIDs: number[];
  piFrameworkIDs: number[];
}
