import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, renderSlot as _renderSlot, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "app-edit-sidebar__form__header" }
const _hoisted_2 = { class: "app-edit-sidebar__form__title" }
const _hoisted_3 = { class: "app-edit-sidebar__form__content" }
const _hoisted_4 = { class: "app-edit-sidebar__form__footer" }

import { watch } from "vue";
import AppButton from "@/components/app/AppButton/AppButton.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppEditSidebar',
  props: /*@__PURE__*/_mergeModels({
    title: {}
  }, {
    "modelValue": { type: Boolean, ...{ required: true } },
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

const model = _useModel<boolean>(__props, "modelValue");



const close = () => {
  model.value = false;
};

watch(
  () => model.value,
  () => {
    document.documentElement.style.overflow = model.value ? "hidden" : "unset";
  },
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['app-edit-sidebar', { 'app-edit-sidebar--hidden': !model.value }]),
    onClick: close
  }, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (model.value)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "app-edit-sidebar__form",
              onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
            }, [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.title), 1),
                _createVNode(AppButton, {
                  onClick: close,
                  padding: "0",
                  icon: "cross",
                  "icon-size": "24px",
                  type: "empty"
                })
              ]),
              _createElementVNode("div", _hoisted_3, [
                _renderSlot(_ctx.$slots, "content")
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(AppButton, {
                  onClick: close,
                  label: "Cancel",
                  type: "empty"
                }),
                _renderSlot(_ctx.$slots, "submit-button")
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ], 2))
}
}

})